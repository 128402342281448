
.header {
    padding: 0.3em 0.7em 0.3em 0.7em;
    text-align: center;
    background: var(--accent);
    color: var(--white);
    font-size: 1em;
    margin-bottom: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
}

.header-spacer {
    padding-bottom: 3em;
}

.header-menu {
    flex: 1;
    display: flex;
}

.header-logo {
    font-size: 1.7em;
    flex: 1;
}

.header-right {
    justify-content: right;
    display: flex;
    flex: 1;
}

.header-site {
    position: relative;
    display: inline-block;
}

.profile-button {
    background: none;
    border: none;
    color: var(--white);
    cursor: pointer;
    padding-right: 2em;
    padding-left: 1em;
}
