.node-zone-select-box {
    cursor: pointer;
    position: relative;
    max-width:  20em;
    margin: 1em;
    width: 30%;
    font-size: 0.95em;
}

.node-zone-submit-button {
    padding: 0.2em 0.5em 0.2em 0.5em;
    font-size: 0.95em;
    margin: 0.2em;
}

.node-zone-submit-button:hover {
    cursor: pointer;
}

.node-zone-:disabled {
    cursor: default;
}

.node-zone-error {
    color: var(--error);
    font-size: 0.9em;
}

.node-zone-success {
    color: var(--accent);
    font-size: 0.9em;
}
