
.capture-tab-container {
    background-color: var(--background);
    padding: 1em;
}

.capture-tab-row {
    margin-bottom: 0.8em;
    padding-bottom: 0.8em; 
    border-bottom: 0.15em solid var(--accent);

    th {
        flex: 1;
    }
}

.capture-container { 
    overflow: hidden;
    transition: 0.3s ease-in-out;
    margin-top: 0.8em;
}

.capture-file-container {
    display: flex;
    flex-direction: column;
    margin-top: 0.8em;
    padding-top: 0.8em; 
    border-top: 0.15em solid var(--accent);
}

.capture-tab-post {
    margin-bottom: 0.8em;
    padding-bottom: 0.8em; 
    border-bottom: 0.15em solid var(--accent);
    display: flex;
}

.captures-container.open { 
    height: auto;
}