.action-button {
    background: none;
    border: none;
    color: var(--button-icon);
    font-size: 1.2em;
    padding-left: 0.8em;
}

.action-button:hover {
    cursor: pointer;
    color: var(--button-hover);
}

.action-button:disabled {
    color: var(--grey);
    cursor: default;
}

.add-statement-button {
    appearance: none;
    -webkit-appearance: none; /* safari */
    border: 1px solid var(--button-border);
    border-radius: 0.25rem;
    background-color: var(--button);
    font-weight: bold;
    font-size: 0.9em;
    padding-bottom: 0.2em;
    margin-bottom: 0.6em;
    color: var(--font-colour);
}

.add-statement-button:hover {
    cursor: pointer;
    background-color: var(--accent-less);
}

.add-statement-button:disabled {
    color: var(--grey);
    cursor: default;
}

.add-rule-button {
    appearance: none;
    -webkit-appearance: none; /* safari */
    border: 1px solid var(--button-border);
    border-radius: 0.25rem;
    background-color: var(--button);
    font-weight: 500;
    font-size: 0.9em;
    margin-top: 0.2em;
    margin-bottom: 1em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    color: var(--font-colour);
}

.add-rule-button:hover {
    cursor: pointer;
    background-color: var(--accent-less);
}

.add-rule-button:disabled {
    color: var(--grey);
    cursor: default;
}

.text-button {
    appearance: none;
    -webkit-appearance: none; /* safari */
    border: 1px solid var(--button-border);
    border-radius: 0.25rem;
    background-color: var(--button);
    font-size: 0.9em;
    color: var(--font-colour);
    padding: 0.4em 0.4em 0.4em 0.4em;
    font-weight: 490;
}

.text-button:hover {
    cursor: pointer !important;
    background-color: var(--text-button-hover) !important;
}

.text-button.hide {
    display: none;
}

.text-button:disabled {
    color: var(--grey);
}

.text-button:disabled:hover {
    background-color: var(--button) !important;
    cursor: default !important;
}

.theme-button {
    color: var(--white);
    background: none;
    border: none;
    font-size: 0.95em;
}

.theme-button:hover {
    cursor: pointer;
    color: var(--container-border);
}
