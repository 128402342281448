body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
    color: var(--font-colour);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1 {
    text-align: left;
}

.nav-link-class {
    color: inherit !important;
}

.nav-link-class.active {
    color: inherit !important;
}

.page-loader {
    background-color: var(--background);
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
