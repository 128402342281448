.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dimmer);
}
  
.modal-main {
    position: fixed;
    background-color: var(--background);
    width: 35rem;
    height: auto;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 1.2em;
}

.modal-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2em;
}

.modal-header {
    margin: 0;
    padding: 0;
}
  
.modal-form {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}

.modal-label {
    float: left;
    clear: left;
    width: auto;
    text-align: left;
    padding-bottom: 1em;
}

.modal-input {
    float: right;
    width: 250px;
    appearance: none;
    -webkit-appearance: none;
    background-color: var(--button);
    border: 1px solid var(--button-border);
    border-radius: 0.25rem;
    padding: 0.5em;
    color: var(--font-colour);
}

.modal-input-checkbox {
    float: right;
    background-color: var(--button);
    border: 1px solid var(--button-border);
    border-radius: 0.25rem;
    padding: 0.5em;
    color: var(--font-colour);
}

.modal-input:disabled {
    color: var(--grey);
}

.modal-exit-button {
    position: absolute;
}

.modal.display-block {
    display: block;
}
  
.modal.display-none {
    display: none;
}
