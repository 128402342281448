.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    width: 100%;
    cursor: pointer;
    min-height: 1.5em;
    min-width: 3em;
    vertical-align: middle;
}

.dropdown-button.icon {
    width: 100%;
    cursor: pointer;
    margin: 0.6em 0 0.6em 0;
    font-size: 1em;
    background-color: transparent;
    border: none;
    justify-items: center;
    color: var(--button-icon);
}

.dropdown-button.icon:hover {
    transition: 0.3s;
    color: var(--accent);
}

.dropdown-content.open {
    display: block;
}

/* Dropdown content (hidden by default) */

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    border: 0.1em solid var(--grey);
    min-width: 8em;
    box-shadow: 0em 0.5em 0.8em 0em var(--box-shadow);
    z-index: 1;
    right: 0;
}

/* Dropdown links */

.dropdown-option {
    color: var(--font-colour);
    padding: 0.7em 1em;
    text-decoration: none;
    display: block;
    text-align: left;
    background-color: var(--background);
    cursor: pointer;
    font-size: 0.98em;
}

.dropdown-option:hover {
    color: var(--font-colour);
    background-color: var(--button-hover);
    transition: 0.3s;
}
