.page-layout-content {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 2rem;
}

.page-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
        height: 100%;
    }
}