.object-summary-table-container {
    background-color: var(--accent-less);
    display: flex;
    padding: 1em;
    justify-content: space-between;
}

.object-summary-table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8em;
    width: 100%;
}

.object-summary-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
}

.object-summary-key {
    font-weight: bold;
}

.object-summary-value {
    margin-top: 0.4em;
    word-break: break-all;
}

.summary-container {
    display: flex;
    align-items: center;
}

.summary-title {
    flex: 1;
    margin-top: 0.2em;
}

@media (min-width: 1150px) {
    .object-summary-table {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 710px) {
    .object-summary-table {
        grid-template-columns: repeat(1, 1fr);
    }
}
