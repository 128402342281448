.json-table-list {
    padding-bottom: 10em;
    text-align: left;
}

.json-table-list h1 {
    text-align: left;
}

.json-table-list table {
    border: 0.15em solid;
    border-color: var(--accent);
    line-height: 2em;
    padding: 0.3em;
    width: 100%;
}

.json-table-list td {
    padding-right: 0.5em;
}

.json-table-dropdown {
    width: 2%;
}

.json-clickable-id {
    text-transform: lowercase;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.json-clickable-id:hover {
    color: var(--accent);
    cursor: pointer;
}

.json-clickable-id:click {
    color: var(--accent);
    cursor: pointer;
}

/* 646 px */
@media (max-width: 646px) {
    .json-table-list table {
        overflow-x: hidden;
        display: block;
    }

    .json-clickable-id {
        text-overflow: ellipsis;
        max-width: calc(100vw - 120px);
    }

    .hidden-on-resize {
        display: none;
    }

    .json-table-dropdown {
        display: none;
    }
}
