.options-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.8em;
    width: 100%;
}

.options-header {
    font-weight: bold;
    padding-bottom: 1em;
}

/* 640 px */
@media (max-width: 646px) {
    .options-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
