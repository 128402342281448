.landing-page-container {
    max-width: 100%;
}

.landing-page {
    background-color: var(--login-accent);
    height: 85vh;
    width: 100%;
    display: grid;
    place-items: start center;
    padding-top: 15vh;
}

.landing-page-title {
    color: var(--login-background);
    font-size: 4em;
    margin-bottom: 0.5em;
    margin-right: 0.2em;
    text-align: center;
}

.login-container {
    width: 17em;
    height: 12em;
    background-color: var(--login-background);
    box-sizing: border-box;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0.5em 0.8em 1em 0em var(--box-shadow-strong);
}

.login-button {
    background-color: var(--login-accent);
    color: var(--login-background);
    font-size: 1.2em;
    font-weight: 500;
    padding: 0.3em;
    border-radius: 0.5em;
    border: none;
    width: 80%;
}

.login-button:hover {
    background-color: var(--button-hover);
    cursor: pointer;
    transition: 0.3s;
}
