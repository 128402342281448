
.policy-history-container {
    border: 1px solid var(--container-border);
    padding: 1em;
}

.policy-history-table {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
}

.policy-history-table-row {
    margin-bottom: 0.8em;
    border-bottom: 0.15em solid var(--accent);

    th {
        width: 25%;
    }
}

.policy-history-ruleset-container { 
    overflow: hidden;
    transition: 0.3s ease-in-out;
    margin-top: 0.8em;

    .rule-container {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-top: 0.15em solid var(--button-hover);
    }
    
    .rule-row {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
}

.policy-history-ruleset-container.open { 
    height: auto;
}

.toggle {
    color: var(--accent);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    width: 70%;
    text-align: left;
    padding-left: 0;
}

.toggle:hover {
    color: var(--button-hover);
}

th.number {
    width: 5%;
}

td.number {
    width: 5%;
}

th.timestamp {
    width: 12%;
}

th.active_periods {
    width: 50%;
}

th.ruleset {
    width: 5%;
}

