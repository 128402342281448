.menu-toggle {
    font-size: 1em;
    background: none;
    border: none;
    color: var(--white);
    cursor: pointer;
    float: left;
    width: 1em;
    padding-right: 1.8em;
}

.menu-content {
    text-align: left;
    padding-left: 1.2em;
    list-style-type: none;
    line-height: 1.7em;
    color: var(--font-colour);
}

.menu-content a {
    transition: 0.3s;
}

.menu-content a:hover {
    color: var(--accent) !important; 
}

.menu-content a:link {
    text-decoration: none;
}

.menu-sidebar {
    margin-top: 2.66em;
    position: fixed;
    top: 0;
    left: -13em;
    width: 200px;
    height: 100vh;
    background-color: var(--background);
    transition: left 0.3s;
    border-right: 0.1em solid var(--accent);
    box-shadow: 0em 0.8em 1.2em 0em var(--box-shadow-strong);
}

.subsection-menu .menu-button {
    background: none;
}

.menu-sidebar.open {
    left: 0;
}

.content.open {
    padding-left: 0;
}

.subsection-menu {
    margin-bottom: 10px;
}