/* Light Mode */
.light {
  --background: #fffafa;
  --font-colour: #000000;
  --accent: #6B4E71;
  --accent-less: #cfbcd173;
  --dimmer: #00000099;
  --button: #ECECEC;
  --button-hover: #806286ae;
  --button-border: #A9A9A9;
  --button-icon: #6B4E71;
  --text-button-hover: #cfbcd173;
  --box-shadow: #00000024;
  --box-shadow-strong: #00000090;
  --container-border: #caced1;
  --tab-hover: #b993c0ae;
  --white: #ffffff;
  --grey: #808080;
  --error: #8B0000;
  --success: #006400;

  --dropdown-arrow: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");

    /* Login page colours - these are the same regardless of theme */

    --login-accent: #58405d;
    --login-background: #fffafa;
}

.dark {
    --background: #232028;
    --font-colour: #fffafa;
    --accent: #805e87;
    --accent-less: #393440;
    --dimmer: #00000099;
    --button: #312d38;
    --button-hover: #957c9a;
    --button-border: #ECECEC;
    --button-icon: #ECECEC;
    --text-button-hover: #45404d;
    --box-shadow: #00000024;
    --box-shadow-strong: #00000090;
    --container-border: #caced1;
    --tab-hover: #6c4f71;
    --white: #ffffff;
    --grey: #808080;
    --error: #c20000;
    --success: #009d00;

    --dropdown-arrow: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");

    /* Login page colours - these are the same regardless of theme */

    --login-accent: #58405d;
    --login-background: #fffafa;
}
