.rules-tab-container {
    border: 1px solid #caced1;
    padding: 1em;

    .rule-container {
        padding-bottom: 0.5em;
        margin-bottom: 0.8em;
        border-bottom: 0.15em solid var(--accent);
    }

    .rule-row {
        padding-bottom: 1em;
    }
}

.rule-container {
    display: flex;
    flex-direction: column;
}

.rule-edit-container {
    display: flex;
    flex-direction: row-reverse; 
    margin-bottom: 1em;
    min-height: 2em;
}

.rule-edit-response-error {
    padding-right: 1em;
    justify-self: left;
    font-size: 0.9em;
    color: darkred;
}

.rule-edit-response-success {
    padding-right: 1em;
    justify-self: left;
    font-size: 0.9em;
    color: var(--success);
}

.rule-row {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

.rule-column1 {
    flex: 0.55;
    font-weight: bold;
}

.rule-column {
    flex: 0.25;
    text-align: right;
}

.row-column-input {
    width: 13vw;
    appearance: none;
    -webkit-appearance: none; /* safari */
    padding: 0.5em 0.5em 0.5em 0.5em;
    background-color: var(--button);
    border: 1px solid var(--button-border);
    border-radius: 0.25rem;
    cursor: pointer;
    color: var(--font-colour);

    /* Dropdown button */

    background-image: var(--dropdown-arrow);
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
}

.row-column-input:hover {
    cursor: pointer;
    background-color: var(--accent-less);
}

.statements-header-container {
    display: grid;
    grid-template-columns: 4fr 25fr 25fr 25fr 3fr;
    width: 100%;
    font-weight: bold;
}

.statements-info-container {
    padding-top: 0.2em;
}

.statements-row-container {
    display: grid;
    grid-template-columns: 4fr 25fr 25fr 25fr 3fr;
    width: 100%;
    padding-bottom: 0.25em;
    padding-top: 0.25em;
}

.statement-value-form input[type=text] {
    max-width: 4em;
}

.statement-value-input {
    width: 8vw;
    appearance: none;
    -webkit-appearance: none;
    background-color: var(--button);
    border: 1px solid var(--button-border);
    border-radius: 0.25rem;
    padding: 0.5em;
    color: var(--font-colour);
}

.statement-column-input {
    width: 20vw;
    appearance: none;
    -webkit-appearance: none;
    padding: 0.5em 0.5em 0.5em 0.5em;
    background-color: var(--button);
    border: 1px solid var(--button-border);
    border-radius: 0.25rem;
    cursor: pointer;
    color: var(--font-colour);

    /* Dropdown button */

    background-image: var(--dropdown-arrow);
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
}

.statement-column-input:hover {
    cursor: pointer;
    background-color: var(--accent-less);
}
