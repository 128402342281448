#userInfo {
    color: var(--font-colour);
    font-size: 1em;
    padding-top: 1.5em;
    margin-top: 2.66em;
    position: fixed;
    top: 0;
    right: 0em;
    width: 18em;
    height: 14em;
    background-color: var(--background);
    border-left: 0.1em solid var(--accent);
    border-bottom: 0.1em solid var(--accent);
    box-shadow: 0em 0.2em 0.5em 0em var(--box-shadow-strong);
}
